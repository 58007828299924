import ProjectCard from './ProjectCard'; 
import burger from "../image/burgerimg.jpg";
import doctorsoffice from "../image/doctorsoffice.jpg";
import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';

export default function Projects() {
  const isSmallScreen = () => window.innerWidth < 850;
  const [isMobile, setIsMobile] = useState(isSmallScreen());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isSmallScreen());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const ref = useRef(null);

 
  const offsetValues = isMobile ? ["0, 1", "0.6, 1"] : ["0.1, 1", "0.8, 0.8"];
  
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: offsetValues 
  });

  const xTransform1 = useTransform(scrollYProgress, [0, 1], ["-70vw", "0vw"]);
  const yTransform1 = useTransform(scrollYProgress, [0, 1], ["-70vh", "0vh"]);

  const xTransform2 = useTransform(scrollYProgress, [0, 1], ["70vw", "0vw"]);
  const yTransform2 = useTransform(scrollYProgress, [0, 1], ["-70vh", "0vh"]);

  const scaleTransform = useTransform(scrollYProgress, [0, 1], [0.3, 1]);
  const opacityTransform = useTransform(scrollYProgress, [0, 1], [0, 1]);

  const transition = {
    type: "spring", 
    stiffness: 200, 
    damping: 20, 
    duration: 1,  
  };

  return (
    <section id="projects">
      <h2>Projects</h2>
      <div className="project-list">
        <motion.div
          ref={ref}
          style={{  
            x: xTransform1,
            y: yTransform1,
            scale: scaleTransform,
            opacity: opacityTransform,
          }}
          transition={transition}  
        >
          <ProjectCard
            img={burger}
            title="Burger Order App"
            description="Burger Order App: A sleek, interactive platform to order burgers. Built with React, Redux."
            onSelect="https://burger-order-app-phi.vercel.app/"
          />
        </motion.div>
        <motion.div
          ref={ref}
          style={{  
            x: xTransform2,
            y: yTransform2,
            scale: scaleTransform,
            opacity: opacityTransform,
          }}
          transition={transition}  
        >
          <ProjectCard
            img={doctorsoffice}
            title="SymptomLog"
            description="A Next.js application where users log in to track health symptoms by time, date, and severity."
            onSelect="https://symptomlog.vercel.app/" 
          />
        </motion.div>
      </div>
    </section>
  );
}
