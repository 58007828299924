import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import logo from "../image/portfolio-black-removebg-preview.png";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 806);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 887);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <header>
      <a href="#top">
        <div className="logo-name">
          <img src={logo} alt="Tsiakiris Nikolaos logo" />
          <h2>Tsiakiris Nikolaos</h2>
        </div>
      </a>
      {isMobile && (
        <motion.div
          className={`hamburger ${isOpen ? "active" : ""}`}
          onClick={toggleMenu}
          whileTap={{ scale: 0.95 }}
        >
          <motion.div className="line" animate={isOpen ? { rotate: 45, y: 8 } : { rotate: 0, y: 0 }} />
          <motion.div className="line" animate={isOpen ? { opacity: 0 } : { opacity: 1 }} />
          <motion.div className="line" animate={isOpen ? { rotate: -45, y: -8 } : { rotate: 0, y: 0 }} />
        </motion.div>
      )}
      <motion.nav
        className={isOpen ? "open" : ""}
        initial={false}
        animate={isMobile ? { x: isOpen ? 0 : "100%" } : { x: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <ul>
          <li><a href="#about" onClick={isMobile ? toggleMenu : undefined}>About</a></li>
          <li><a href="#resume" onClick={isMobile ? toggleMenu : undefined}>Resume</a></li>
          <li><a href="#projects" onClick={isMobile ? toggleMenu : undefined}>Projects</a></li>
          <li><a href="#contact" onClick={isMobile ? toggleMenu : undefined}>Contact</a></li>
        </ul>
      </motion.nav>
    </header>
  );
}