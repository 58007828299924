import "./background.css";
import video from "../../image/backgroundvideo.mp4";
import fallbackImage from "../../image/fallbackimage.jpg";

export default function Background () {
  return (
    <>
      <div className="shadow-overlay"></div>
      <video
        playsInline
        autoPlay
        muted
        loop
        preload="auto"
        id="bg"
        poster={fallbackImage}
      >
        <source src={video} type="video/mp4" />
      </video>
    </>
  );
};

