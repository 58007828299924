

export default function ProjectCard({ title, description, img, onSelect }) {
  function handleButtonClick() {
    if (onSelect) {
      window.open(onSelect, '_blank');
    }
  }

  return (
    <div className="project-card-box">
      <div className="project-card">
        {img && <img src={img} alt="Project" />}
        <div className="project-title">
          <h3>{title}</h3>
        </div>
        <div className="project-description">
          <p>{description}</p>
        </div>
        <button className="project-btn" onClick={handleButtonClick}>
          <span className="btn-content">
            Visit the app
          </span>
        </button>
      </div>
    </div>
  );
}