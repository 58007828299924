// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGithub, faLinkedin, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
// import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS
import { motion, useScroll, useTransform } from 'framer-motion';

export default function Contact() {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const contactTextRef = useRef(null); 
  const { scrollYProgress } = useScroll({
    target: contactTextRef,
    offset: ["0.1 1", "0.7 1"] 
  });
  
  const textOpacity = useTransform(scrollYProgress, [0, 0.2], [0, 1]);  
  const formOpacity = useTransform(scrollYProgress, [0.6, 0.8], [0, 1]);  



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const form = formRef.current;
       const result = await emailjs.sendForm('service_dxnt43f', 'template_oxyzlza', form, 'I-JQ3QNYTWeGxbdAz');

      console.log(result.text);
      alert('Form submitted successfully!');
      form.reset(); // Clear the input fields
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to submit the form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id='contact'>
       <motion.div
        className="contact-text-container"
        ref={contactTextRef}
        style={{
          opacity: textOpacity,    
          textAlign: "center",
          color: "white",
          transition: "opacity 0.5s ease-in-out",
          top: "120px",
        }}
      >
      <h2>Contact Me</h2>
      </motion.div>

      <motion.div
        className="contact-text-container"
        ref={contactTextRef}
        style={{
          opacity: formOpacity,    
          transition: "opacity 0.8s ease-in-out",
        }}
      >
      <div className="contact-container">
       
        <div className="contact-form">
          <form ref={formRef} onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" required />
            <input 
              type="submit" 
              value={loading ? "Sending data..." : "Submit"} 
              disabled={loading} 
            />
            {error && <p className="error-message">{error}</p>}
          </form>
        </div>
      </div>
      </motion.div>
    </section>
  );
}
